<template>
  <div class="myAssAppeal">
    <!-- 我的考核结果 -->
    <van-nav-bar :title="title"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <div class="app_details">
        <van-field class="empcode"
                   :label="$t('jxAppealsDetail.adTxt1')"
                   :value="appDetails.empcode"
                   readonly />
        <van-field :label="$t('jxAppealsDetail.adTxt2')"
                   :value="appDetails.empname"
                   readonly />
        <van-field :label="$t('jxAppealsDetail.adTxt3')"
                   :value="appDetails.khtimename"
                   readonly />
        <van-field :label="$t('jxAppealsDetail.adTxt4')"
                   :value="appDetails.planname"
                   readonly />
        <van-field :label="sstype == '按结果申诉'?$t('jxAppealsDetail.adTxt5'):$t('jxAppealsDetail.adTxt6')"
                   :value="sstype == '按结果申诉'?appDetails.khres:appDetails.itemname"
                   readonly />
        <van-field :label="sstype == '按结果申诉'?$t('jxAppealsDetail.adTxt7'):$t('jxAppealsDetail.adTxt8')"
                   :value="appDetails.lastscore"
                   readonly />
        <van-field v-if="sstype != '按结果申诉'"
                   :label="$t('jxAppealsDetail.adTxt9')"
                   :value="appDetails.itemper"
                   readonly />
        <van-field v-if="sstype != '按结果申诉'"
                   :label="$t('jxAppealsDetail.adTxt10')"
                   :value="appDetails.tgvalue"
                   readonly />
        <div class="presonAvar">
          <img style="border-radius: 100%"
               :src="appDetails.picurl" />
        </div>
      </div>
      <div class="app_reason">
        <!-- <div class="text">期望等级：</div> -->
        <!-- <div class="reqkhres"> -->
        <van-field class="qwdflll"
                   v-model="appDetails.reqkhscore"
                   autosize
                   :label="$t('jxAppealsDetail.adTxt11')"
                   type="input"
                   show-word-limit
                   readonly
                   v-if="sstype != '按结果申诉'" />
        <van-field v-if="appDetails.sstype != '按指标申诉'"
                   class="reqkhres_cell"
                   :value="appDetails.reqkhres"
                   readonly
                   :label="$t('jxAppealsDetail.adTxt12')" />
        <!-- </div> -->
        <div class="text">{{$t('jxAppealsDetail.adTxt13')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="appDetails.apreason"
                     rows="5"
                     autosize
                     readonly
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
      </div>
      <div class="app_desc">
        <div class="text">{{$t('jxAppealsDetail.adTxt14')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="appDetails.remark"
                     rows="3"
                     autosize
                     readonly
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
      </div>
      <div v-if="isMyapp == 'false'"
           class="app_button">
        <van-button type="info"
                    color="#ef2e45"
                    size="small"
                    @click="operate(false)">{{$t('jxAppealsDetail.adTxt15')}}</van-button>
        <van-button type="info"
                    size="small"
                    @click="operate(true)">{{$t('jxAppealsDetail.adTxt16')}}</van-button>
      </div>
      <div v-if="isMyapp == 'true' && ssstatus == '已处理'  && isqr == 'false'"
           class="app_button">
        <van-button class="comfirmBtn"
                    type="info"
                    size="small"
                    @click="confirmRst">{{$t('jxAppealsDetail.adTxt21')}}</van-button>
      </div>
    </div>

    <!-- 不同意面试弹窗 -->
    <van-dialog v-model="showBTYDia"
                :title="$t('jxAppealsDetail.adTxt15')"
                show-cancel-button
                :before-close="closeBTYDia">
      <div style="padding: 10px">
        <van-cell class="desc_cell"
                  required
                  :value="$t('jxAppealsDetail.adTxt17')" />
        <van-field class="desc_field"
                   v-model="fjreason"
                   rows="3"
                   autosize
                   type="textarea"
                   :placeholder="$t('jxAppealsDetail.adTxt17')" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { getSsDetail, postXjSsInfo, qrJxSs } from '@api/wxjx.js'
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      title: this.$route.query.title,
      isMyapp: this.$route.query.isMyapp,
      mautoid: this.$route.query.mautoid,
      empcode: this.$route.query.empcode,
      sstype: this.$route.query.sstype,
      ssstatus: this.$route.query.ssstatus,
      isqr: this.$route.query.isqr,
      appDetails: {},
      reqkhres: '',
      apreason: '',
      remark: '',
      fjreason: null, // 否决原因
      showBTYDia: false,
      reqkhscore: ''
    }
  },
  computed: {
    disabled () {
      return this.reqkhres && this.apreason
    }
  },
  created () {
    this.getAppealDetail()
  },
  methods: {
    onClickLeft () {
      this.$router.push({
        path: '/jxPerformanceAppeals',
        query: { isMyapp: this.isMyapp }
      })
    },
    // 查详情
    getAppealDetail () {
      getSsDetail({
        autoid: this.mautoid,
        username: this.empcode,
      }).then((res) => {
        console.log(res.data)
        this.appDetails = res.data.length > 0 ? res.data[0] : {};
      });
    },
    // 否决/同意按钮
    operate (val) {
      // 清空原因
      this.fjreason = null
      if (val)
      {
        // 同意
        Dialog.confirm({
          message: this.$t('jxAppealsDetail.adTxt18')
        })
          .then(() => {
            // on confirm
            this.submit(true)
          })
          .catch(() => {
            // on cancel
          })
      } else
      {
        // 否决
        this.showBTYDia = true
      }
    },
    // 不同意弹窗回调
    closeBTYDia (action, done) {
      if (action == 'confirm')
      {
        if (this.fjreason)
        {
          this.submit(false)
          done()
        } else
        {
          Toast.fail(this.$t('jxAppealsDetail.adTxt19'))
          done(false)
        }
      } else
      {
        done()
      }
    },
    // 提交
    submit (check) {
      postXjSsInfo({
        autoid: this.mautoid,
        ischeck: check,
        fjreason: this.fjreason,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast(res.data[0].info)
        } else
        {
          Toast.success(this.$t('jxAppealsDetail.adTxt20'))
          this.onClickLeft()
        }
      })
    },
    // 确认申诉结果
    confirmRst () {
      qrJxSs({
        autoid: this.mautoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast(res.data[0].info)
        } else
        {
          Toast.success(this.$t('jxAppealsDetail.adTxt22'))
          this.onClickLeft()
        }
      })

    }
  }
}
</script>

<style lang="less" scoped>
.myAssAppeal {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 90px);
    .qwdflll {
      padding: 0 0 20px;
      &::after {
        display: none !important;
      }
      /deep/.van-cell__title {
        span {
          color: #ef2e45;
        }
      }
      /deep/.van-field__body {
        input {
          border: 1px solid #ef2e45;
          padding-left: 20px;
        }
      }
    }
    .app_details {
      position: relative;
      /deep/.van-cell {
        .van-cell__title {
          color: #ababab;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              color: #ababab;
            }
          }
        }
      }
      /deep/.empcode {
        .van-cell__title {
          color: #ababab;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              color: #2b8df0 !important;
            }
          }
        }
      }
      .van-cell::after {
        border-bottom: 0;
      }
      .presonAvar {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 122px;
        min-width: 122px;
        height: 122px;
        border-radius: 122px;
        border: 2px solid #2b8df0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .app_reason {
      margin: 30px 0;
      padding: 30px;
      background: #fff;
      .text {
        font-size: 28px;
        color: #ef2e45;
      }
      .reqkhres {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        padding: 4px;
        margin-bottom: 30px;
        .van-cell::after {
          border-bottom: 0;
        }
      }
      .reqkhres_cell {
        padding: 20px 0;
        margin-bottom: 20px;
        /deep/.van-field__label {
          color: #ef2e45;
        }
      }
      .thrid_desc {
        margin: 16px 4px 4px 4px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        .brief {
          border-radius: 12px;
        }
      }
    }
    .app_desc {
      margin: 30px 0;
      padding: 30px;
      background: #fff;
      .text {
        font-size: 28px;
        // color: #ef2e45;
      }
      .thrid_desc {
        margin: 16px 4px 4px 4px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        .brief {
          border-radius: 12px;
        }
      }
    }
    .app_button {
      background: #fff;
      padding: 20px 60px;
      display: flex;
      justify-content: space-around;
      .van-button {
        border-radius: 20px;
        width: 40%;
      }
      .comfirmBtn {
        width: 80%;
      }
    }
  }
}
</style>